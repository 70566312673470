import React from 'react';

const LOAForm = ({ register }) => {
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 p-6 rounded-xl shadow-lg border border-gray-700">
        <div className="grid gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Location</label>
            <input
              {...register('location')}
              placeholder="Enter location details"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Access to Electrical</label>
            <input
              {...register('accessToElectrical')}
              placeholder="Specify electrical access requirements"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Work To Be Done</label>
            <textarea
              {...register('workToBeDone')}
              placeholder="Describe the work in detail"
              rows="4"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Prepared By</label>
            <input
              {...register('preparedBy')}
              placeholder="Enter name of preparer"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Date Prepared</label>
            <input
              {...register('datePrepared')}
              type="date"
              defaultValue={today}
              disabled
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg text-gray-400 cursor-not-allowed"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">RAMS</label>
            <input
              {...register('rams')}
              placeholder="Enter RAMS details"
              className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LOAForm;
