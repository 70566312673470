import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';

const IssuePermitModal = ({ isOpen, onClose, onIssue, permitData }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    issuedBy: currentUser?.displayName || '',
    issuerContactNumber: currentUser?.phoneNumber || '',
    signature: currentUser?.signature || '',
    issuedTo: '',
    recipientContactNumber: '',
    company: ''
  });
  const [cpUsers, setCpUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      issuedBy: currentUser?.displayName || '',
      issuerContactNumber: currentUser?.phoneNumber || '',
      signature: currentUser?.signature || ''
    }));
  }, [currentUser]);

  useEffect(() => {
    const fetchCPUsers = async () => {
      try {
        const q = query(
          collection(db, 'CPRegister'),
          where('testPassed', '==', true)
        );
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCpUsers(users);
      } catch (error) {
        console.error('Error fetching CP users:', error);
      }
    };
    fetchCPUsers();
  }, []);

  const filteredUsers = cpUsers.filter(user =>
    user.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserSelect = (user) => {
    setFormData(prev => ({
      ...prev,
      issuedTo: user.name || '',
      recipientContactNumber: user.phoneNumber || '',
      company: user.companyName || ''
    }));
    setIsSearching(false);
    setSearchTerm('');
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    if (!formData.issuedTo) {
      alert('Please select a recipient');
      return;
    }

    const updatedPermitData = {
      ...permitData,
      status: 'Issued',
      issuedDetails: {
        issuedBy: {
          name: currentUser?.displayName,
          contactNumber: currentUser?.phoneNumber,
          signature: currentUser?.signature
        },
        issuedDateAndTime: new Date().toISOString(),
        issuedTo: {
          name: formData.issuedTo,
          contactNumber: formData.recipientContactNumber,
          company: formData.company
        }
      }
    };
    onIssue(updatedPermitData);
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Issue Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4">
          {/* Issuer Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Issuer Details</h3>
            <div className="text-sm text-gray-200 bg-gray-800 p-3 rounded-md border border-gray-700">
              {formData.issuedBy}
            </div>
          </div>

          {/* Recipient Section */}
          <div className="space-y-4 mt-6">
            <h3 className="text-lg font-medium text-gray-100">Recipient Details</h3>
            <div className="space-y-3">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-300">
                  Search Recipient
                </label>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsSearching(e.target.value.length >= 3);
                  }}
                  onFocus={() => setIsSearching(searchTerm.length >= 3)}
                  placeholder="Search by name (min 3 characters)..."
                  className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-2 transform transition-all duration-200 focus:-translate-y-1"
                />
                {isSearching && searchTerm.length >= 3 && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-auto">
                    {filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="p-2 hover:bg-gray-700 cursor-pointer text-gray-200"
                      >
                        <div>{user.name}</div>
                        <div className="text-sm text-gray-400">{user.companyName}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Selected Recipient
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.issuedTo || 'No recipient selected'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Contact Number
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.recipientContactNumber || 'N/A'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Company
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.company || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Issue
          </button>
        </div>
      </div>
    </div>
  );
};

export default IssuePermitModal;
