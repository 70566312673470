import React, { useState } from 'react';
import { usePermitForm } from './hooks/usePermitForm';
import { permitValidation } from '../../utils/permitValidation';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';

function PermitRequests() {
  const { handleSubmit: submitForm } = usePermitForm(permitValidation);
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    company: '',
    documentHolder: '',
    emailRequestor: '',
    telephone: '',
    dateOfRequest: '',
    dateRequired: '',
    workDuration: '',
    rams: '',
    workType: '',
    location: [],
    equipment: '',
    workToBeDone: '',
    roomNamesNumbers: '',
    isolationRequired: '',
    sapApRequired: '',
    otherInfo: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter(item => item !== value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'permitRequests'), {
        ...formData,
        status: 'Pending',
        createdAt: new Date()
      });
      
      setFormData({
        email: '',
        fullName: '',
        company: '',
        documentHolder: '',
        emailRequestor: '',
        telephone: '',
        dateOfRequest: '',
        dateRequired: '',
        workDuration: '',
        rams: '',
        workType: '',
        location: [],
        equipment: '',
        workToBeDone: '',
        roomNamesNumbers: '',
        isolationRequired: '',
        sapApRequired: '',
        otherInfo: ''
      });
      
      alert('LOTO Safety Document Request submitted successfully!');
    } catch (error) {
      console.error('Error submitting request:', error);
      alert('An error occurred while submitting the request. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto mb-8 bg-gray-800 text-gray-100 p-8 rounded-lg shadow-lg">
      <h3 className="text-3xl font-bold mb-6 text-blue-400">LOTO Safety Document Request</h3>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-300 mb-1">Full Name Requestor</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="documentHolder" className="block text-sm font-medium text-gray-300 mb-1">Safety Document Holder</label>
            <input
              type="text"
              id="documentHolder"
              name="documentHolder"
              value={formData.documentHolder}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="emailRequestor" className="block text-sm font-medium text-gray-300 mb-1">Email Address Requestor</label>
            <input
              type="email"
              id="emailRequestor"
              name="emailRequestor"
              value={formData.emailRequestor}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="telephone" className="block text-sm font-medium text-gray-300 mb-1">Telephone Number</label>
            <input
              type="tel"
              id="telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label htmlFor="dateOfRequest" className="block text-sm font-medium text-gray-300 mb-1">Date of Request</label>
            <input
              type="date"
              id="dateOfRequest"
              name="dateOfRequest"
              value={formData.dateOfRequest}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="dateRequired" className="block text-sm font-medium text-gray-300 mb-1">Date Required</label>
            <input
              type="date"
              id="dateRequired"
              name="dateRequired"
              value={formData.dateRequired}
              onChange={handleChange}
              className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div>
          <label htmlFor="workDuration" className="block text-sm font-medium text-gray-300 mb-1">Expected Duration of Work</label>
          <input
            type="text"
            id="workDuration"
            name="workDuration"
            value={formData.workDuration}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="rams" className="block text-sm font-medium text-gray-300 mb-1">Risk Assessment/Method Statement Number (RAMS)</label>
          <input
            type="text"
            id="rams"
            name="rams"
            value={formData.rams}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Is this work Mechanical or Electrical?</p>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="workType"
                value="Mechanical"
                checked={formData.workType === 'Mechanical'}
                onChange={handleChange}
                className="form-radio text-blue-500"
                required
              />
              <span className="ml-2">Mechanical</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="workType"
                value="Electrical"
                checked={formData.workType === 'Electrical'}
                onChange={handleChange}
                className="form-radio text-blue-500"
                required
              />
              <span className="ml-2">Electrical</span>
            </label>
          </div>
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Location</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {['Ground Floor', 'First Floor', 'Second Floor', 'AMS/E', 'SBO1', 'ECO1', 'Required'].map((loc) => (
              <label key={loc} className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="location"
                  value={loc}
                  checked={formData.location.includes(loc)}
                  onChange={handleChange}
                  className="form-checkbox text-blue-500"
                />
                <span className="ml-2">{loc}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="equipment" className="block text-sm font-medium text-gray-300 mb-1">Equipment to be worked on</label>
          <input
            type="text"
            id="equipment"
            name="equipment"
            value={formData.equipment}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="workToBeDone" className="block text-sm font-medium text-gray-300 mb-1">Work to be done</label>
          <textarea
            id="workToBeDone"
            name="workToBeDone"
            value={formData.workToBeDone}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          ></textarea>
        </div>

        <div>
          <label htmlFor="roomNamesNumbers" className="block text-sm font-medium text-gray-300 mb-1">Room Names and Numbers</label>
          <input
            type="text"
            id="roomNamesNumbers"
            name="roomNamesNumbers"
            value={formData.roomNamesNumbers}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Will an isolation be required?</p>
          <div className="flex space-x-4">
            {['YES', 'NO', 'UNSURE'].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="isolationRequired"
                  value={option}
                  checked={formData.isolationRequired === option}
                  onChange={handleChange}
                  className="form-radio text-blue-500"
                  required
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-300 mb-2">Will an SAP/AP be required to operate equipment?</p>
          <div className="flex space-x-4">
            {['YES', 'NO', 'UNSURE'].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  name="sapApRequired"
                  value={option}
                  checked={formData.sapApRequired === option}
                  onChange={handleChange}
                  className="form-radio text-blue-500"
                  required
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="otherInfo" className="block text-sm font-medium text-gray-300 mb-1">Any other information</label>
          <textarea
            id="otherInfo"
            name="otherInfo"
            value={formData.otherInfo}
            onChange={handleChange}
            className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          ></textarea>
        </div>

        <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md transition duration-300">
          Submit Request
        </button>
      </form>
    </div>
  );
}

export default PermitRequests;
