import React from 'react';

const MPTWForm = ({ register }) => {
  return (
    <div className="space-y-4">
      <input
        {...register('mechanicalSystem')}
        placeholder="Mechanical System/Equipment"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('mechanicalHazards')}
        placeholder="Mechanical Hazards"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('lockoutProcedures')}
        placeholder="Lockout/Tagout Procedures"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('specialTools')}
        placeholder="Special Tools Required"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
};

export default MPTWForm;