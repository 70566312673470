import React, { useState } from 'react';
import logo from '../../../assets/logo.png';

const LOA_Template = ({ permitData = {} }) => {
  // Destructure permitData with default values
  const {
    number = '001',
    location = '',
    accessToElectrical = '',
    workToBeDone = '',
    rams = '',
    qrCodeUrl,
  } = permitData;

  return (
    <div className="flex flex-col items-center bg-gray-100 p-4">
      {/* First Page */}
      <div className="w-[210mm] h-[297mm] bg-white shadow-lg p-4 text-sm flex flex-col mb-8">
        {/* Header */}
        <div className="bg-blue-400 p-4 flex items-center justify-between">
          {/* Left container: Logo */}
          <div className="flex-1 flex items-center">
            <img src={logo} alt="Logo" className="w-45 h-14" />
          </div>

          {/* Middle container: LIMITATION OF ACCESS */}
          <div className="flex-1 flex justify-center">
            <h1 className="text-xl font-bold text-white">LIMITATION OF ACCESS</h1>
          </div>

          {/* Right container: LOA Number */}
          <div className="flex-1 flex justify-end">
            <div className="bg-blue-500 text-white font-semibold text-xl p-2">
              LOA WTX-{number}
            </div>
          </div>
        </div>
        
        {/* Section 1 */}
        <div className=" border-2 text-gray-950 ">
          <h2 className="font-bold">1. INFO</h2>
          <p>(i) LOCATION - {location}</p>
          <p>(ii) ACCESS TO ELECTRICAL: {accessToElectrical}</p>
          <p>(iii) WORK TO BE DONE: {workToBeDone}</p>
        </div>

        {/* Section 2 */}
        <div className=" border-2 p-2 text-gray-950">
          <h2 className="font-bold">2. PRECAUTIONS TAKEN TO ACHIEVE SAFETY FROM THE SYSTEM</h2>
          <p>(i) LIMITS OF WORK:</p>
          <p className="text-xs">WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO ELECTRICAL PANELS DURING THIS WORK OTHER THAN THOSE SPECIFIED IN SECTION 1 (iii). NO OTHER WORKS ALLOWED UNDER THIS DOCUMENT. PERSONAL SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 415 VOLTS OR 10,000 VOLTS, SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.</p>
          <p>(ii) LIMITS OF WORK AREA:</p>
          <p className="text-xs">AS STATED IN SECTION 1 (i) AND SECTION 1 (ii)</p>
          <p>(iii) FURTHER PRECAUTIONS TO BE TAKEN DURING THE WORK TO AVOID SYSTEM DERIVED HAZARDS*</p>
          <p className="text-xs">ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {rams} NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/415 VOLTS.</p>
          <p className="text-xs">NOTE: THE 10,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/415 VOLTS. ALL TESTING TO BE CARRIED OUT USING APPROVED/ CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.</p>
        </div>

        {/* Section 3 */}
        <div className=" p-2 border-2 text-black">
          <h2 className="font-bold">3. PREPARATION</h2>
          <p className="text-xs">I CONFIRM THAT THE PRECAUTIONS IN SECTION 2(i) & 2(ii) HAVE BEEN CARRIED OUT AND WILL BE MAINTAINED UNTIL THIS LIMITATION OF ACCESS IS CANCELLED.</p>
          <p className="text-xs">I CERTIFY THAT THE PRECAUTIONS CARRIED OUT IN SECTION 2 ARE ADEQUATE TO PROVIDE SAFETY FROM THE SYSTEM FOR THE WORK SPECIFIED IN SECTION 1</p>
          <div className="grid grid-cols-3 gap-4 mt-2">
            <div className="border border-black p-1">AUTHORISED PERSON/ SENIOR AUTHORISED PERSON - NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1">SIGNATURE</div>
            <div className="border border-black p-1">CONTACT DETAILS*</div>
          </div>
          <div className="flex justify-end mt-2">
            <span className="mr-4">TIME</span>
            <div className="border-b border-black w-16"></div>
            <span className="mx-4">DATE</span>
            <div className="border-b border-black w-24"></div>
          </div>
        </div>

        {/* Section 4 */}
        <div className=" p-2 border-2 text-black">
          <h2 className="font-bold">4. ISSUE</h2>
          <p>AUTHORISED PERSON/ OR SENIOR AUTHORISED PERSON RESPONSIBLE FOR THE ISSUE OF THIS DOCUMENT</p>
          <div className="grid grid-cols-5 gap-2 mt-2">
            <div className="border border-black p-1 col-span-2">AUTHORISED PERSON / SENIOR AUTHORISED PERSON - NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1">SIGNATURE</div>
            <div className="border border-black p-1">CONTACT DETAILS*</div>
            <div className="border border-black p-1">TIME</div>
            <div className="border border-black p-1">DATE</div>
          </div>
          <p className="mt-2">SUPPORTING DOCUMENTATION & ITEMS ATTACHED*</p>
          <p>SLD □ ………………… LAYOUT DRAWING □………………………….. ACCESS KEYS □……………….. OTHER□……………..........</p>
        </div>

        {/* Section 5 */}
        <div className=" p-2 border-2 text-black">
          <h2 className="font-bold">5. RECEIPT</h2>
          <p className="text-xs">I BEING THE COMPETENT PERSON IN CHARGE UNDERSTAND AND ACCEPT MY RESPONSIBILITIES FOR THE WORKS SPECIFIED IN SECTION 1(iii)</p>
          <p className="text-xs">I HEREBY RECEIVE THIS DOCUMENT & ANY SUPPORTING DOCUMENTATION & ITEMS AS DETAILED IN SECTION 4</p>
          <p className="text-xs">IN ADDITION GENERAL SAFETY REQUIREMENTS HAVE BEEN MET AND RISKS MITIGATED</p>
          <div className="grid grid-cols-6 gap-2 mt-2">
            <div className="border border-black p-1 col-span-2">COMPETENT PERSON -- NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1">Badge ID NO.</div>
            <div className="border border-black p-1">COMPANY</div>
            <div className="border border-black p-1">SIGNATURE</div>
            <div className="border border-black p-1">TIME</div>
            <div className="border border-black p-1">DATE</div>
          </div>
          <div className="border border-black p-1 mt-2">COMPETENT PERSON CONTACT DETAILS*</div>
        </div>

        <p className="text-xs mt-4">*write N/A if not applicable</p>
      </div>

      {/* Second Page */}
      <div className="w-[210mm] h-[297mm] bg-white shadow-lg p-8 text-sm flex flex-col">
        {/* Section 6 */}
        <div className="bg-orange-200 p-2 mb-4">
          <h2 className="font-bold">6. TRANSFER RECORD</h2>
          <p className="text-xs">A SAFETY DOCUMENT MAY BE SURRENDERED BY THE COMPETENT PERSON AFTER COMPLETING SECTION 6, TRANSFER RECORD OF THIS SAFETY DOCUMENT. A SAFETY DOCUMENT SHALL ONLY BE REISSUED BY AN AUTHORISED PERSON/ SENIOR AUTHORISED PERSON WHO WILL REISSUE TO THE SAME STANDARD AS IF IT WAS BEING ISSUED FOR THE FIRST TIME.</p>
          <div className="grid grid-cols-3 gap-2 mt-2">
            <div className="border border-black p-1">SURRENDER BY</div>
            <div className="border border-black p-1">SAP / AP</div>
            <div className="border border-black p-1">RE-ISSUE</div>
          </div>
          {/* Repeat this grid 4 times for the transfer record table */}
          {[...Array(4)].map((_, index) => (
            <div key={index} className="grid grid-cols-3 gap-2 mt-2">
              <div className="border border-black p-1">
                <p>COMPETENT PERSON</p>
                <p>TIME</p>
                <p>DATE</p>
              </div>
              <div className="border border-black p-1">
                <p>PRINT NAME</p>
                <p>SIGNED</p>
                <p>CONTACT DETAILS*</p>
              </div>
              <div className="border border-black p-1">
                <p>COMPETENT PERSON</p>
                <p>TIME</p>
                <p>DATE</p>
              </div>
            </div>
          ))}
        </div>

        {/* Section 7 */}
        <div className="bg-indigo-200 p-2 mb-4">
          <h2 className="font-bold">7. CLEARANCE</h2>
          <p className="text-xs">I CERTIFY THAT ALL PERSONS WORKING UNDER THIS LIMITATION OF ACCESS HAVE BEEN WITHDRAWN FROM AND WARNED IT IS NO LONGER SAFE TO CARRY OUT THE WORK IN SECTION 1. ALL PERSONS HAVE BEEN REMOVED FROM THE WPR. ALL GEAR, TOOLS & LOOSE MATERIALS HAVE ALSO BEEN REMOVED AND ALL COVERS, GUARDS AND ACCESS DOORS HAVE BEEN REPLACED.</p>
          <p className="text-xs">THIS LIMITATION OF ACCESS IS CLEARED. THE FOLLOWING EXCEPTIONS ARE RECORDED *</p>
          <div className="border border-black p-1 h-20 mt-2"></div>
          <div className="grid grid-cols-4 gap-2 mt-2">
            <div className="border border-black p-1">COMPETENT PERSON NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1">SIGNATURE</div>
            <div className="border border-black p-1">TIME</div>
            <div className="border border-black p-1">DATE</div>
          </div>
          <p className="mt-2">SUPPORTING DOCUMENTATION & ITEMS RETURNED*</p>
          <p>SLD □ ………………………… P&ID □…………………..… ACCESS KEYS □………………….….. OTHER□……………......................................</p>
        </div>

        {/* Section 8 */}
        <div className="bg-teal-200 p-2">
          <h2 className="font-bold">8. CANCELLATION</h2>
          <p className="text-xs">I CERTIFY THAT THE SAP/ CONTROL ENGINEER HAS BEEN INFORMED OF ANY EXCEPTIONS ASSOCIATED WITH THIS LIMITATION OF ACCESS & PERMISSION FROM THE SAP/ CONTROL ENGINEER HAS BEEN GRANTED TO CANCEL THIS LIMITATION OF ACCESS.</p>
          <div className="grid grid-cols-5 gap-2 mt-2">
            <div className="border border-black p-1 col-span-2">CONTROL ENGINEER/ SAP -- NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1 col-span-2">AUTHORISED PERSON / SENIOR AUTHORISED PERSON - NAME (IN BLOCK CAPITALS)</div>
            <div className="border border-black p-1">SIGNATURE</div>
            <div className="border border-black p-1">TIME</div>
            <div className="border border-black p-1">DATE</div>
          </div>
        </div>

        <p className="text-xs mt-4">*write N/A if not applicable</p>
      </div>
    </div>
  );
};

export default LOA_Template;
