import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, ResponsiveContainer } from 'recharts';
import { Calendar, Zap, UserRoundPen, ClipboardPenLine, FileSearch} from 'lucide-react';
import keyPlan from '../assets/keyplan.jpg';
import FormattedDate from '../Layout/FormattedDate';

const data = [
  { name: 'Jan', value: 300 },
  { name: 'Feb', value: 200 },
  { name: 'Mar', value: 350 },
  { name: 'Apr', value: 400 },
  { name: 'May', value: 250 },
  { name: 'Jun', value: 300 },
];

const StatCard = ({ title, value, change, icon }) => (
  <div className="bg-gray-800 rounded-lg p-4 flex flex-col">
    <div className="text-xs text-gray-400 mb-2">{title}</div>
    <div className="text-2xl font-bold mb-2">{value}</div>
    <div className={`text-xs ${change > 0 ? 'text-green-400' : 'text-red-400'}`}>
      {change > 0 ? '+' : ''}{change}%
    </div>
    <div className="mt-2 text-gray-400">{icon}</div>
  </div>
);

const CalendarItem = ({ time, title, person, color }) => (
  <div className="flex items-center mb-4">
    <div className={`w-2 h-2 rounded-full ${color} mr-2`}></div>
    <div className="flex-grow">
      <div className="text-sm">{title}</div>
      <div className="text-xs text-gray-400">{time} • {person}</div>
    </div>
    <button className="text-gray-400">•••</button>
  </div>
);

function Dashboard() {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">Loading...</div>;
  }

  if (!currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Welcome, {currentUser.email}</h1>
        <p className="text-gray-400">Role: {currentUser.role}</p>
   
        
      </div>

      <div className="bg-gray-800 rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
        <div className="bg-gray-700 rounded-lg p-4 flex items-center justify-between">
            <span>Project Progress</span>
            <span className="bg-green-400 text-gray-900 rounded-full px-2 py-1 text-xs">On Track - Day 1</span>
          </div>
          <div className="flex items-center space-x-4">
            <select className="bg-gray-700 rounded-full px-4 py-2">
              <option>WTX London Project</option>
            </select>
            <div className="bg-gray-700 rounded-full px-4 py-2 flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span><FormattedDate /></span>
            </div>
          </div>
        </div>
        <img src={keyPlan} alt="Logo" className="w-full mb-4" />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 bg-gray-700 rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg">Monthly Permits</h3>
              <button className="bg-gray-600 text-green-400 rounded-full px-3 py-1 text-sm">Monthly Permits</button>
            </div>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={data}>
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                <Bar dataKey="value" fill="#4ade80" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
            <div className="flex justify-between mt-4">
              <div>
                <div className="text-xs text-gray-400">Minimum</div>
                <div className="text-lg font-bold">89</div>
              </div>
              <div>
                <div className="text-xs text-gray-400">Maximum</div>
                <div className="text-lg font-bold text-green-400">150</div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-gray-700 rounded-lg p-4">
              <h3 className="text-lg mb-4">Energisation Requests</h3>
              <CalendarItem title="PMDC-B1-2-A" person="Paul Smee" color="bg-purple-400" />
              <CalendarItem  title="AHU-R-300-03" person="Allyn Coady" color="bg-purple-400" />
              <CalendarItem  title="TX-B1-2-A" person="Stephen Bryson" color="bg-blue-400" />
              <CalendarItem  title="GEN-A1-2-A" person="Alin Tonu" color="bg-green-400" />
              <CalendarItem  title="FCU-00-218-01" person="Florin Pirvu" color="bg-orange-400" />
            </div>
            <button className="bg-green-400 text-gray-900 rounded-full w-10 h-10 flex items-center justify-center text-2xl font-bold">+</button>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
          <StatCard title="Permit Requests" value="27" change={-12} icon={<UserRoundPen className="w-6 h-6" />} />
          <StatCard title="Permit Issued" value="45" change={8} icon={<ClipboardPenLine className="w-6 h-6" />} />
          <StatCard title="Energisation Requests" value="24" change={-4} icon={<FileSearch className="w-6 h-6" />} />
          <StatCard title="Assets Energized" value="61%" change={2} icon={<Zap className="w-6 h-6" />} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          
        
         
        </div>
       

       
      </div>
    </div>
  );
}

function AdminDashboard() {
  return <div className="text-lg text-gray-300">Admin Dashboard Content</div>;
}

function APDashboard() {
  return <div className="text-lg text-gray-300">AP Dashboard Content</div>;
}

function SAPDashboard() {
  return <div className="text-lg text-gray-300">SAP Dashboard Content</div>;
}

export default Dashboard;