import React from 'react';

const QuestionContainer = ({ question, onAnswerSelected, selectedAnswers, darkMode }) => {
  if (!question) {
    return <div className="text-center">Loading question...</div>;
  }

  const handleAnswerClick = (index) => {
    if (question.multipleAnswers) {
      const newSelectedAnswers = selectedAnswers.includes(index)
        ? selectedAnswers.filter(i => i !== index)
        : [...selectedAnswers, index];
      onAnswerSelected(newSelectedAnswers);
    } else {
      onAnswerSelected([index]);
    }
  };

  return (
    <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg mb-6`}>
      <h3 className="text-xl font-semibold mb-4">{question.text}</h3>
      <p className="mb-4">
        {question.multipleAnswers ? "Select all that apply:" : "Select one answer:"}
      </p>
      <ul className="space-y-2">
        {question.options.map((option, index) => (
          <li key={index}>
            <button 
              onClick={() => handleAnswerClick(index)}
              className={`w-full text-left p-3 rounded ${
                selectedAnswers.includes(index)
                  ? (darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                  : (darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300')
              }`}
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuestionContainer;
