import React, { useState, useEffect } from 'react';
import usePermits from './hooks/usePermits';
import { usePermitContext } from './context/PermitContext';
import { permitStatusHelper } from './utils/permitStatusHelper';
import ViewPermitModal from '../modals/ViewPermitModal';
import PermitTypesTabs from './PermitTypesTabs';
import StatusTabs from './StatusTabs';
import PermitList from './PermitList';



function PermitsStatus() {
  const { loading, fetchPermits, handleIssuePermit, handleClosePermit } = usePermits();
  const { getStatusColor } = permitStatusHelper;
  // State variables
  const [activePermitType, setActivePermitType] = useState('LOA');
  const [activeStatus, setActiveStatus] = useState('Prepared');
  const [permits, setPermits] = useState([]);
  const [selectedPermit, setSelectedPermit] = useState(null);

  const handleViewPermit = (permit) => {
    setSelectedPermit(permit);
  };


  // Effect hook to fetch permits when the active permit type or status changes
  const { refreshTrigger } = usePermitContext();

  useEffect(() => {
    const loadPermits = async () => {
      const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
      setPermits(fetchedPermits);
    };
    loadPermits();
  }, [activePermitType, activeStatus, refreshTrigger]);

  // Function to close the permit view
  const closePermitView = () => {
    setSelectedPermit(null);
  };

  const onIssuePermit = async (permit) => {
    const result = await handleIssuePermit(permit.id);
    if (result) {
      const updatedPermits = permits.map(p => 
        p.id === permit.id ? { ...p, status: 'Issued' } : p
      );
      setPermits(updatedPermits);
    }
  };

  const onClosePermit = async (permit) => {
    const result = await handleClosePermit(permit.id);
    if (result) {
      const updatedPermits = permits.map(p => 
        p.id === permit.id ? { ...p, status: 'Closed' } : p
      );
      setPermits(updatedPermits);
    }
  };

  if (loading) {
    return <div className="text-white">Loading permits...</div>;
  }

  return (
    <div className="bg-gray-900 text-gray-100 p-6 rounded-lg shadow-lg">
      <PermitTypesTabs 
        activePermitType={activePermitType}
        setActivePermitType={setActivePermitType}
      />
      
      <StatusTabs 
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
      />
      
      <PermitList
        permits={permits}
        handleViewPermit={handleViewPermit}
        handleIssuePermit={onIssuePermit}
        handleClosePermit={onClosePermit}
      />

      <ViewPermitModal 
        isOpen={!!selectedPermit}
        onClose={() => setSelectedPermit(null)}
        permitData={selectedPermit}
      />
    </div>
  );
}

export default PermitsStatus;
