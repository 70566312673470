import React from 'react';
import LOA_Template from '../PermitsStatus/templates/LOA_Template';
import EPTW_Template from '../PermitsStatus/templates/EPTW_Template';
import MPTW_Template from '../PermitsStatus/templates/MPTW_Template';
import { generateLOAPermitPDF } from '../PermitsStatus/services/pdfService';


const ViewPermitModal = ({ isOpen, onClose, permitData }) => {
  if (!isOpen) return null;

  const handleDownloadPDF = () => {
    if (permitData.type === 'LOA') {
      const doc = generateLOAPermitPDF(permitData);
      doc.save(`LOA-${permitData.number || '001'}.pdf`);
    }
  };


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {permitData.type === 'LOA' && <LOA_Template permitData={permitData} />}
        {permitData.type === 'EPTW' && <EPTW_Template permitData={permitData} />}
        {permitData.type === 'MPTW' && <MPTW_Template permitData={permitData} />}
        <div className="mt-4 flex space-x-4">
          <button
            onClick={handleDownloadPDF}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            disabled={permitData.type !== 'LOA'}
          >
            Download PDF
          </button>
          <button
            onClick={onClose}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewPermitModal;
