import React from 'react';
import PermitsTable from './PermitsTable';
import PermitsStatus from './PermitsStatus/PermitsStatus';
import { PermitProvider } from './PermitsStatus/context/PermitContext';

function Permits() {
  return (
    <PermitProvider>
      <div className="p-8">
        <h2 className="text-3xl font-bold mb-4">Permits</h2>
        <PermitsTable />
        <PermitsStatus />
      </div>
    </PermitProvider>
  );
}

export default Permits;
