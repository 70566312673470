import React from 'react';
import { permitStatusHelper } from './utils/permitStatusHelper';
import PermitActions from './PermitActions';

const PermitList = ({ 
  permits, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit 
}) => {
  const { getStatusColor } = permitStatusHelper;

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-800">
            <th className="border border-gray-700 p-2">Number</th>
            <th className="border border-gray-700 p-2">Prepared By</th>
            <th className="border border-gray-700 p-2">Location</th>
            <th className="border border-gray-700 p-2">Work to be Done</th>
            <th className="border border-gray-700 p-2">Date Prepared</th>
            <th className="border border-gray-700 p-2">Status</th>
            <th className="border border-gray-700 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {permits.map((permit) => (
            <tr key={permit.id} className="hover:bg-gray-800 transition-colors duration-200">
              <td className="border border-gray-700 p-2">{permit.number}</td>
              <td className="border border-gray-700 p-2">{permit.preparedBy}</td>
              <td className="border border-gray-700 p-2">{permit.location}</td>
              <td className="border border-gray-700 p-2">{permit.workToBeDone}</td>
              <td className="border border-gray-700 p-2">
                {permit.createdAt?.toDate().toLocaleString()}
              </td>
              <td className="border border-gray-700 p-2">
                <span className={`px-2 py-1 rounded ${getStatusColor(permit.status)}`}>
                  {permit.status}
                </span>
              </td>
              <td className="border border-gray-700 p-2">
                <PermitActions
                  permit={permit}
                  handleViewPermit={handleViewPermit}
                  handleIssuePermit={handleIssuePermit}
                  handleClosePermit={handleClosePermit}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermitList;
