import { db } from '../../../../services/firebase';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';

export const PermitService = {
  fetchPermits: async (type, status) => {
    try {
      const permitsCollection = collection(db, 'permits');
      const q = query(
        permitsCollection,
        where('type', '==', type),
        where('status', '==', status)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching permits:', error);
      throw new Error('Failed to fetch permits');
    }
  },

  viewPermit: async (permitId) => {
    try {
      const permitRef = doc(db, 'permits', permitId);
      const permitSnap = await getDoc(permitRef);
      if (!permitSnap.exists()) {
        throw new Error('Permit not found');
      }
      return { id: permitSnap.id, ...permitSnap.data() };
    } catch (error) {
      console.error('Error viewing permit:', error);
      throw new Error('Failed to view permit');
    }
  },

  issuePermit: async (permitId) => {
    try {
      const permitRef = doc(db, 'permits', permitId);
      await updateDoc(permitRef, { status: 'Issued' });
      return { id: permitId, status: 'Issued' };
    } catch (error) {
      console.error('Error issuing permit:', error);
      throw new Error('Failed to issue permit');
    }
  },

  closePermit: async (permitId) => {
    try {
      const permitRef = doc(db, 'permits', permitId);
      await updateDoc(permitRef, { status: 'Closed' });
      return { id: permitId, status: 'Closed' };
    } catch (error) {
      console.error('Error closing permit:', error);
      throw new Error('Failed to close permit');
    }
  }
};
