import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { ChevronDown, ChevronUp } from 'lucide-react'; 

const CPRegister = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'CPRegister'));
        const registrationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRegistrations(registrationsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching registrations:', error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const filteredRegistrations = searchTerm.length >= 3 
    ? registrations.filter(registration => {
        const searchString = searchTerm.toLowerCase();
        return (
          registration.name?.toLowerCase().includes(searchString) ||
          registration.email?.toLowerCase().includes(searchString) ||
          registration.companyName?.toLowerCase().includes(searchString) ||
          registration.jobTitle?.toLowerCase().includes(searchString) ||
          registration.phoneNumber?.toLowerCase().includes(searchString)
        );
      })
    : registrations;

  const handleRowClick = (registrationId) => {
    setExpandedRow(expandedRow === registrationId ? null : registrationId);
  };

  const handleDelete = async (e, registrationId) => {
    e.stopPropagation(); // Prevent row expansion when clicking delete
    
    if (window.confirm('Are you sure you want to delete this registration? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'CPRegister', registrationId));
        setRegistrations(prevRegistrations => 
          prevRegistrations.filter(reg => reg.id !== registrationId)
        );
      } catch (error) {
        console.error('Error deleting registration:', error);
        alert('Failed to delete registration. Please try again.');
      }
    }
  };

  const WrongAnswersDetail = ({ wrongAnswers }) => {
    if (!wrongAnswers || wrongAnswers.length === 0) {
      return <div className="p-4 text-gray-300">No wrong answers recorded.</div>;
    }

    return (
      <div className="p-4 bg-gray-800 space-y-4">
        <h3 className="text-lg font-semibold text-gray-200 mb-3">Wrong Answers ({wrongAnswers.length})</h3>
        {wrongAnswers.map((answer, idx) => (
          <div key={idx} className="border border-gray-700 rounded-lg p-4 space-y-2">
            <p className="text-gray-200 font-medium">{answer.questionText}</p>
            
            <div className="space-y-1">
              <p className="text-sm text-gray-400">Options:</p>
              {answer.options.map((option, optIdx) => (
                <div 
                  key={optIdx} 
                  className={`text-sm pl-4 ${
                    answer.userAnswers.includes(optIdx) 
                      ? 'text-red-400' 
                      : answer.correctAnswers.includes(optIdx)
                      ? 'text-green-400'
                      : 'text-gray-300'
                  }`}
                >
                  {option} {
                    answer.userAnswers.includes(optIdx) && answer.correctAnswers.includes(optIdx) 
                      ? '✓'
                      : answer.userAnswers.includes(optIdx) 
                      ? '✗' 
                      : answer.correctAnswers.includes(optIdx)
                      ? '(correct)'
                      : ''
                  }
                </div>
              ))}
            </div>

            {answer.multipleAnswers && (
              <p className="text-xs text-gray-400 mt-2">This was a multiple-choice question</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 text-gray-100">
      <h1 className="text-2xl font-bold mb-4 text-gray-100">CP Register</h1>
      
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="overflow-x-auto rounded-lg border border-gray-700">
        <table className="min-w-full bg-gray-800">
          <thead className="bg-gray-900">
            <tr>
              <th className="w-10 px-6 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Company</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Job Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Phone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Test Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Score</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredRegistrations.map((registration) => (
              <React.Fragment key={registration.id}>
                <tr 
                  className={`hover:bg-gray-700 transition-colors cursor-pointer ${
                    expandedRow === registration.id ? 'bg-gray-700' : ''
                  }`}
                  onClick={() => handleRowClick(registration.id)}
                >
                  <td className="px-6 py-4">
                    {registration.lastTestDetails?.wrongAnswers && (
                      <button className="text-gray-400">
                        {expandedRow === registration.id ? (
                          <ChevronUp className="h-5 w-5" />
                        ) : (
                          <ChevronDown className="h-5 w-5" />
                        )}
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.companyName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.jobTitle}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.phoneNumber}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      registration.testStatus === 'PASSED'
                        ? 'bg-green-100 text-green-800'
                        : registration.testStatus === 'FAILED'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {registration.testStatus || 'Not Started'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {registration.rawScore != null ? (
                      <span>
                        {registration.rawScore}/20 ({registration.testScore}%)
                      </span>
                    ) : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {registration.lastTestAttempt ? 
                      new Date(registration.lastTestAttempt.seconds * 1000).toLocaleString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                      : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={(e) => handleDelete(e, registration.id)}
                      className="text-red-500 hover:text-red-700 font-medium px-3 py-1 rounded-md hover:bg-red-100/10 transition-colors"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                {expandedRow === registration.id && registration.lastTestDetails && (
                  <tr>
                    <td colSpan="9" className="bg-gray-800">
                      <WrongAnswersDetail wrongAnswers={registration.lastTestDetails.wrongAnswers} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CPRegister;
