import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

const ClosePermitModal = ({ isOpen, onClose, onClose: onClosePermit, permitData }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    closedBy: currentUser?.displayName || '',
    closerContactNumber: currentUser?.phoneNumber || '',
    signature: currentUser?.signature || '',
    remarks: '',
  });

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      closedBy: currentUser?.displayName || '',
      closerContactNumber: currentUser?.phoneNumber || '',
      signature: currentUser?.signature || ''
    }));
  }, [currentUser]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    if (!formData.closedBy || !formData.closerContactNumber || !formData.signature) {
      alert('Please fill in all required fields');
      return;
    }
    
    const updatedPermitData = {
      ...permitData,
      ...formData,
      status: 'Closed',
      closedAt: new Date().toISOString(),
      closedBy: formData.closedBy,
      closerContactNumber: formData.closerContactNumber,
      signature: formData.signature,
      remarks: formData.remarks || ''
    };
    onClosePermit(updatedPermitData);
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Close Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4">
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Closer Details</h3>
            <div className="space-y-3">
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Closed By
                </label>
                <input
                  type="text"
                  name="closedBy"
                  value={formData.closedBy}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Contact Number
                </label>
                <input
                  type="text"
                  name="closerContactNumber"
                  value={formData.closerContactNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Signature
                </label>
                <div className="mt-1 p-2 block w-full h-24 border border-gray-600 bg-gray-800">
                  {formData.signature && (
                    <img 
                      src={formData.signature} 
                      alt="Signature" 
                      className="h-full object-contain"
                    />
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Remarks
                </label>
                <textarea
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
                  rows="3"
                  placeholder="Enter closing remarks..."
                />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-red-500"
          >
            Close Permit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClosePermitModal;
