import { useState } from 'react';

export const usePermitForm = (validateFn) => {
  const [errors, setErrors] = useState({});

  const handleSubmit = (event, formData) => {
    event.preventDefault();
    const validationErrors = validateFn(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // Submit the form
      console.log('Form submitted:', formData);
    }
  };

  return { errors, handleSubmit };
};