import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import TestIntroduction from './TestIntroduction';
import QuestionContainer from './QuestionContainer';
import TestProgress from './TestProgress';
import TestSubmission from './TestSubmission';
import TestResults from './TestResults';
import CPTestSuccess from './CPTestSuccess';

const CPTest = () => {
  const [darkMode, setDarkMode] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isExternalUser = location.state?.isExternalUser || false;
  const registrationId = location.state?.registrationId;
  const userId = location.state?.userId;
  const userIdentifier = isExternalUser ? registrationId : userId;
  const isGuest = !userIdentifier;

  // Add new state for questions
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [testStatus, setTestStatus] = useState('not started');
  const [score, setScore] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [testCompletionTime, setTestCompletionTime] = useState(null);
  const [testPassed, setTestPassed] = useState(false);

  // Fetch questions from Firebase
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionBankRef = collection(db, 'questionBank');
        const querySnapshot = await getDocs(questionBankRef);
        const fetchedQuestions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setQuestions(fetchedQuestions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching questions:', err);
        setError('Failed to load questions. Please try again later.');
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    if (!registrationId && !userId) {
      navigate('/cp-request', { replace: true });
    }
  }, [registrationId, userId, navigate]);

  useEffect(() => {
    if (!location.state) {
      navigate('/cp-request', { replace: true });
    }
  }, [location, navigate]);

  const passingScore = 70;

  const selectRandomQuestions = useCallback(() => {
    if (questions.length === 0) return [];
    const shuffled = [...questions].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 20);
  }, [questions]);

  const handleStartTest = () => {
    if (questions.length === 0) {
      setError('No questions available. Please try again later.');
      return;
    }
    const selectedQs = selectRandomQuestions();
    console.log('Selected questions:', selectedQs);
    setSelectedQuestions(selectedQs);
    setTestStatus('in-progress');
    setUserAnswers(new Array(selectedQs.length).fill([]));
    setSelectedAnswers([]);
  };

  const updateAnswer = (answers) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestion] = answers;
    setUserAnswers(newUserAnswers);
    setIsAnswered(answers.length > 0);
  };

  const nextQuestion = () => {
    if (currentQuestion < selectedQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswers(userAnswers[currentQuestion + 1] || []);
      setIsAnswered(userAnswers[currentQuestion + 1]?.length > 0);
    }
  };

  const previousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswers(userAnswers[currentQuestion - 1] || []);
      setIsAnswered(userAnswers[currentQuestion - 1]?.length > 0);
    }
  };

  const handleAnswerSelected = (answers) => {
    setSelectedAnswers(answers);
    updateAnswer(answers);
  };

  const calculateScore = () => {
    return userAnswers.reduce((total, userAnswer, index) => {
      const question = selectedQuestions[index];
      const correctAnswers = new Set(question.correctAnswers);
      const selectedAnswers = new Set(userAnswer);
      
      if (question.multipleAnswers) {
        const allCorrectSelected = question.correctAnswers.every(answer => selectedAnswers.has(answer));
        const noIncorrectSelected = userAnswer.every(answer => correctAnswers.has(answer));
        return allCorrectSelected && noIncorrectSelected ? total + 1 : total;
      } else {
        return userAnswer.length === 1 && correctAnswers.has(userAnswer[0]) ? total + 1 : total;
      }
    }, 0);
  };

 
  const processWrongAnswers = () => {
    const wrongAnswers = selectedQuestions.reduce((wrong, question, index) => {
      const userAnswer = userAnswers[index];
      const correctAnswers = new Set(question.correctAnswers);
      const selectedAnswers = new Set(userAnswer);
      
      let isCorrect;
      if (question.multipleAnswers) {
        const allCorrectSelected = question.correctAnswers.every(answer => selectedAnswers.has(answer));
        const noIncorrectSelected = userAnswer.every(answer => correctAnswers.has(answer));
        isCorrect = allCorrectSelected && noIncorrectSelected;
      } else {
        isCorrect = userAnswer.length === 1 && correctAnswers.has(userAnswer[0]);
      }

      if (!isCorrect) {
        wrong.push({
          questionId: question.id,
          questionText: question.text,
          options: question.options,
          correctAnswers: question.correctAnswers,
          userAnswers: userAnswer,
          multipleAnswers: question.multipleAnswers
        });
      }
      return wrong;
    }, []);

    return wrongAnswers;
  };

  const updateCertificationStatus = async (passed, rawScore) => {
    if (!isGuest) {
      const cpRegisterRef = doc(db, 'CPRegister', userIdentifier);
      const currentDate = new Date();
      
      const wrongAnswers = processWrongAnswers();
      
      try {
        console.log('Updating certification status:', {
          userIdentifier,
          passed,
          rawScore,
          wrongAnswersCount: wrongAnswers.length
        });
        
        const updateData = {
          testStatus: passed ? 'PASSED' : 'FAILED',
          lastTestAttempt: currentDate,
          rawScore: rawScore, // Store the raw score (0-20)
          testScore: Math.round((rawScore / selectedQuestions.length) * 100), // Keep percentage for historical compatibility
          testPassed: passed,
          isExternalUser: isExternalUser,
          lastTestDetails: {
            totalQuestions: selectedQuestions.length,
            wrongAnswers: wrongAnswers,
            timestamp: currentDate
          }
        };
  
        await updateDoc(cpRegisterRef, updateData);
        console.log('Successfully updated certification status and stored wrong answers');
      } catch (error) {
        console.error('Error updating certification status:', error);
        console.error('Error details:', error.message);
        throw error;
      }
    }
  };

  const handleSubmitTest = async () => {
    try {
      const calculatedScore = calculateScore();
      const scorePercentage = (calculatedScore / selectedQuestions.length) * 100;
      setScore(calculatedScore);
      const passed = scorePercentage >= passingScore;
      
      await updateCertificationStatus(passed, calculatedScore);
      
      setTestCompletionTime(new Date().toISOString());
      setTestStatus('completed');
      setTestPassed(passed);
      
      console.log('Test submitted successfully:', {
        score: calculatedScore,
        percentage: scorePercentage,
        passed
      });
    } catch (error) {
      console.error('Failed to submit test:', error);
      alert('There was an error submitting your test. Please try again.');
    }
  };

  const handleRetakeTest = () => {
    const newQuestions = selectRandomQuestions();
    setSelectedQuestions(newQuestions);
    setCurrentQuestion(0);
    setUserAnswers(new Array(newQuestions.length).fill([]));
    setScore(0);
    setTestStatus('not started');
    setIsAnswered(false);
  };

  const handleReturnToDashboard = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
        <div className="container mx-auto px-4 py-8 flex justify-center items-center">
          <div className="text-xl">Loading questions...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
        <div className="container mx-auto px-4 py-8">
          <div className="text-xl text-red-500 text-center">{error}</div>
          <button 
            onClick={() => window.location.reload()}
            className={`mt-4 px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white mx-auto block`}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-center">CP Test Module</h1>
        <div className="max-w-3xl mx-auto">
          {testStatus === 'not started' && (
            <TestIntroduction onStartTest={handleStartTest} darkMode={darkMode} />
          )}
          {testStatus === 'in-progress' && (
            <>
              <TestProgress 
                currentQuestion={currentQuestion}
                totalQuestions={selectedQuestions.length}
                darkMode={darkMode}
              />
              <QuestionContainer 
                question={selectedQuestions[currentQuestion]}
                onAnswerSelected={handleAnswerSelected}
                selectedAnswers={selectedAnswers}
                darkMode={darkMode}
              />
              <div className="flex justify-between mt-6">
                <button 
                  onClick={previousQuestion} 
                  disabled={currentQuestion === 0}
                  className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white disabled:opacity-50`}
                >
                  Previous
                </button>
                <button 
                  onClick={nextQuestion} 
                  disabled={currentQuestion === selectedQuestions.length - 1}
                  className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white disabled:opacity-50`}
                >
                  Next
                </button>
              </div>
              {currentQuestion === selectedQuestions.length - 1 && (
                <TestSubmission onSubmitTest={handleSubmitTest} darkMode={darkMode} />
              )}
            </>
          )}
          {testStatus === 'completed' && testPassed && (
            <CPTestSuccess
              score={score}
              totalQuestions={selectedQuestions.length}
              testCompletionTime={testCompletionTime}
              darkMode={darkMode}
            />
          )}
          {testStatus === 'completed' && !testPassed && (
            <TestResults
              score={score}
              totalQuestions={selectedQuestions.length}
              passingScore={passingScore}
              onRetakeTest={handleRetakeTest}
              onReturnToDashboard={handleReturnToDashboard}
              darkMode={darkMode}
              userAnswers={userAnswers}
              selectedQuestions={selectedQuestions}
              testCompletionTime={testCompletionTime}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CPTest;