import React, { useState } from 'react';
import { FaEye, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import IssuePermitModal from '../modals/IssuePermitModal';
import ClosePermitModal from '../modals/ClosePermitModal';

const PermitActions = ({ 
  permit, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit 
}) => {
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  const handleOpenIssueModal = () => setIsIssueModalOpen(true);
  const handleCloseIssueModal = () => setIsIssueModalOpen(false);
  
  const handleOpenCloseModal = () => setIsCloseModalOpen(true);
  const handleCloseCloseModal = () => setIsCloseModalOpen(false);

  const handleIssueConfirm = async (permitData) => {
    await handleIssuePermit(permitData);
    handleCloseIssueModal();
  };

  const handleCloseConfirm = async (permitData) => {
    const success = await handleClosePermit(permitData);
    if (success) {
      handleCloseCloseModal();
    }
  };
  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => handleViewPermit(permit)}
        className="text-blue-500 hover:text-blue-700"
        title="View Permit"
      >
        <FaEye />
      </button>
      <button
        onClick={handleOpenIssueModal}
        className="text-green-500 hover:text-green-700"
        title="Issue Permit"
        disabled={permit.status !== 'Prepared'}
      >
        <FaCheckCircle />
      </button>
      <IssuePermitModal
        isOpen={isIssueModalOpen}
        onClose={handleCloseIssueModal}
        onIssue={handleIssueConfirm}
        permitData={permit}
      />
      <button
        onClick={handleOpenCloseModal}
        className="text-red-500 hover:text-red-700"
        title="Close Permit"
        disabled={permit.status !== 'Issued'}
      >
        <FaTimesCircle />
      </button>
      <ClosePermitModal
        isOpen={isCloseModalOpen}
        onClose={handleCloseCloseModal}
        onClose={handleCloseConfirm}
        permitData={permit}
      />
    </div>
  );
};

export default PermitActions;
