import React from 'react';

const EPTWForm = ({ register }) => {
  return (
    <div className="space-y-4">
      <input
        {...register('equipmentDescription')}
        placeholder="Equipment Description"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('electricalHazards')}
        placeholder="Electrical Hazards"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('precautionsMeasures')}
        placeholder="Precautions/Safety Measures"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        {...register('ppe')}
        placeholder="Personal Protective Equipment"
        className="w-full p-3 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
};

export default EPTWForm;