import { jsPDF } from 'jspdf';
import logo from '../../../assets/logo.png';

// Helper function to add bordered section
const addBorderedSection = (doc, { title, content, startY, height }) => {
  const margin = 5;
  const pageWidth = doc.internal.pageSize.width;
  const sectionWidth = pageWidth - (margin * 2);
  
  // Draw border
  doc.setDrawColor(0);
  doc.rect(margin, startY, sectionWidth, height);
  
  // Add title with less padding
  doc.setFont('helvetica', 'bold');
  doc.text(title, margin + 2, startY + 5);
  
  // Add content with adjusted padding and text wrapping
  doc.setFont('helvetica', 'normal');
  const maxWidth = pageWidth - (margin * 4);
  const splitContent = doc.splitTextToSize(content, maxWidth);
  return doc.text(splitContent, margin + 2, startY + 10);
};

// Helper function to wrap text and get height
const getWrappedTextHeight = (doc, text, maxWidth) => {
  const lines = doc.splitTextToSize(text, maxWidth);
  return lines.length * doc.getTextDimensions('Text').h;
};

export const generateLOAPermitPDF = (permitData) => {
  // Initialize PDF document with proper dimensions
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4'
  });

  // Page configuration
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 10;

  // Set default font
  doc.setFont('helvetica');
  
  // Header section
  // Blue header background
  doc.setFillColor(147, 197, 253); // bg-blue-400 equivalent
  doc.rect(0, 0, pageWidth, 30, 'F');

  // Add logo
  const logoWidth = 45;
  const logoHeight = 14;
  doc.addImage(logo, 'PNG', margin, 8, logoWidth, logoHeight);

  // Permit number box (top right)
  doc.setFillColor(59, 130, 246); // bg-blue-500 equivalent
  doc.rect(pageWidth - 50, 5, 40, 15, 'F');
  
  // Add permit number text
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(14);
  doc.text(`LOA WTX-${permitData.number || '001'}`, pageWidth - 45, 15);

  // Add title text (center)
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('LIMITATION OF ACCESS', pageWidth / 2, 20, { align: 'center' });

  // Reset text color and font for main content
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(12);

  // Section 1 - INFO
  let currentY = 35;
  const section1Content = [
    `(i) LOCATION - ${permitData.location || 'N/A'}`,
    `(ii) ACCESS TO ELECTRICAL: ${permitData.accessToElectrical || 'N/A'}`,
    `(iii) WORK TO BE DONE: ${permitData.workToBeDone || 'N/A'}`
  ].join('\n\n');

  // Calculate height needed for section 1
  doc.setFontSize(11);
  const section1Height = getWrappedTextHeight(doc, section1Content, pageWidth - (margin * 2) - 4) + 15;

  addBorderedSection(doc, {
    title: '1. INFO',
    content: section1Content,
    startY: currentY,
    height: section1Height
  });

  // Section 2 - PRECAUTIONS
  currentY += section1Height + 2;
  const section2Title = '2. PRECAUTIONS TAKEN TO ACHIEVE SAFETY FROM THE SYSTEM';
  
  const limitsText = 'WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO ELECTRICAL PANELS DURING THIS WORK OTHER THAN THOSE SPECIFIED IN SECTION 1 (iii). NO OTHER WORKS ALLOWED UNDER THIS DOCUMENT. PERSONAL SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 415 VOLTS OR 10,000 VOLTS, SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.';
  
  const section2Content = [
    '(i) LIMITS OF WORK:',
    limitsText,
    '\n(ii) LIMITS OF WORK AREA:',
    'AS STATED IN SECTION 1 (i) AND SECTION 1 (ii)',
    '\n(iii) FURTHER PRECAUTIONS TO BE TAKEN DURING THE WORK TO AVOID SYSTEM DERIVED HAZARDS*',
    `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS ${permitData.rams || 'N/A'} NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/415 VOLTS.`,
    '\nNOTE: THE 10,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/415 VOLTS. ALL TESTING TO BE CARRIED OUT USING APPROVED/ CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.'
  ].join('\n\n'); // Added extra newline between sections

  // Calculate height needed for section 2
  doc.setFontSize(10);
  const section2Height = getWrappedTextHeight(doc, section2Content, pageWidth - (margin * 2) - 4) + 25; // Increased padding

  addBorderedSection(doc, {
    title: section2Title,
    content: section2Content,
    startY: currentY,
    height: section2Height
  });

  // Update currentY after section 2
  currentY += section2Height + 2; // Added extra spacing before section 3

  // Section 3 - PREPARATION
  const section3Content = 'I CONFIRM THAT THE PRECAUTIONS IN SECTION 2(i) & 2(ii) HAVE BEEN CARRIED OUT AND WILL BE MAINTAINED UNTIL THIS LIMITATION OF ACCESS IS CANCELLED.\n\nI CERTIFY THAT THE PRECAUTIONS CARRIED OUT IN SECTION 2 ARE ADEQUATE TO PROVIDE SAFETY FROM THE SYSTEM FOR THE WORK SPECIFIED IN SECTION 1';
  
  addBorderedSection(doc, {
    title: '3. PREPARATION',
    content: section3Content,
    startY: currentY,
    height: 40
  });

  // Add grid for section 3
  currentY += 40;
  const gridWidth = (pageWidth - (margin * 2)) / 3;
  
  doc.rect(margin, currentY, gridWidth, 10);
  doc.rect(margin + gridWidth, currentY, gridWidth, 10);
  doc.rect(margin + (gridWidth * 2), currentY, gridWidth, 10);
  
  doc.setFontSize(8);
  doc.text('AUTHORISED PERSON/ SENIOR\nAUTHORISED PERSON - NAME', margin + 2, currentY + 4);
  doc.text('SIGNATURE', margin + gridWidth + 2, currentY + 4);
  doc.text('CONTACT DETAILS*', margin + (gridWidth * 2) + 2, currentY + 4);

  // Time and date line
  currentY += 12;
  doc.setFontSize(10);
  doc.text('TIME _____________ DATE _____________', pageWidth - margin - 80, currentY);

  // Section 4 - ISSUE
  currentY += 2;
  const section4Content = 'AUTHORISED PERSON/ OR SENIOR AUTHORISED PERSON RESPONSIBLE FOR THE ISSUE OF THIS DOCUMENT';
  
  addBorderedSection(doc, {
    title: '4. ISSUE',
    content: section4Content,
    startY: currentY,
    height: 35
  });

  // Add grid for section 4
  currentY += 20;
  const smallGridWidth = (pageWidth - (margin * 2)) / 5;
  
  doc.rect(margin, currentY, smallGridWidth * 2, 10);
  doc.rect(margin + (smallGridWidth * 2), currentY, smallGridWidth, 10);
  doc.rect(margin + (smallGridWidth * 3), currentY, smallGridWidth, 10);
  doc.rect(margin + (smallGridWidth * 4), currentY, smallGridWidth, 10);

  doc.setFontSize(8);
  doc.text('AP/SAP NAME', margin + 2, currentY + 4);
  doc.text('SIGNATURE', margin + (smallGridWidth * 2) + 2, currentY + 4);
  doc.text('CONTACT', margin + (smallGridWidth * 3) + 2, currentY + 4);
  doc.text('TIME/DATE', margin + (smallGridWidth * 4) + 2, currentY + 4);

  // Supporting documentation line
  currentY += 12;
  doc.setFontSize(10);
  doc.text('SUPPORTING DOCUMENTATION & ITEMS ATTACHED*', margin, currentY);
  currentY += 6;
  doc.text('SLD □ _______ LAYOUT DRAWING □_______ ACCESS KEYS □_______ OTHER□_______', margin, currentY);

  // Section 5 - RECEIPT
  currentY += 2;
  const section5Content = 'I BEING THE COMPETENT PERSON IN CHARGE UNDERSTAND AND ACCEPT MY RESPONSIBILITIES FOR THE WORKS SPECIFIED IN SECTION 1(iii)\n\nI HEREBY RECEIVE THIS DOCUMENT & ANY SUPPORTING DOCUMENTATION & ITEMS AS DETAILED IN SECTION 4\n\nIN ADDITION GENERAL SAFETY REQUIREMENTS HAVE BEEN MET AND RISKS MITIGATED';
  
  addBorderedSection(doc, {
    title: '5. RECEIPT',
    content: section5Content,
    startY: currentY,
    height: 45
  });

  // Add grid for section 5
  currentY += 47;
  const receiptGridWidth = (pageWidth - (margin * 2)) / 6;
  
  doc.rect(margin, currentY, receiptGridWidth * 2, 10);
  doc.rect(margin + (receiptGridWidth * 2), currentY, receiptGridWidth, 10);
  doc.rect(margin + (receiptGridWidth * 3), currentY, receiptGridWidth, 10);
  doc.rect(margin + (receiptGridWidth * 4), currentY, receiptGridWidth, 10);
  doc.rect(margin + (receiptGridWidth * 5), currentY, receiptGridWidth, 10);

  doc.setFontSize(8);
  doc.text('COMPETENT PERSON NAME', margin + 2, currentY + 4);
  doc.text('Badge ID', margin + (receiptGridWidth * 2) + 2, currentY + 4);
  doc.text('COMPANY', margin + (receiptGridWidth * 3) + 2, currentY + 4);
  doc.text('SIGNATURE', margin + (receiptGridWidth * 4) + 2, currentY + 4);
  doc.text('TIME/DATE', margin + (receiptGridWidth * 5) + 2, currentY + 4);

  // Contact details field
  currentY += 12;
  doc.rect(margin, currentY, pageWidth - (margin * 2), 10);
  doc.text('COMPETENT PERSON CONTACT DETAILS*', margin + 2, currentY + 4);

  // Footer note
  currentY += 14;
  doc.setFontSize(8);
  doc.text('*write N/A if not applicable', margin, currentY);

  // Note: Logo and QR code would need to be added as images
  // This requires the images to be converted to base64 first
  // Will be implemented in future iterations

  return doc;
};
