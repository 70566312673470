import React from 'react';

const StatusTabs = ({ activeStatus, setActiveStatus }) => {
  const statusTabs = ['Prepared', 'Issued', 'Expired', 'Closed'];

  return (
    <div className="flex mb-4 bg-gray-800 rounded-lg overflow-hidden">
      {statusTabs.map((status) => (
        <button
          key={status}
          className={`px-4 py-2 flex-1 ${
            activeStatus === status ? 'bg-blue-600 text-white' : 'text-gray-300 hover:bg-gray-700'
          } transition-colors duration-200`}
          onClick={() => setActiveStatus(status)}
        >
          {status}
        </button>
      ))}
    </div>
  );
};

export default StatusTabs;
