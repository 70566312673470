import React, { useState, useEffect } from 'react';
import usePermits from './PermitsStatus/hooks/usePermits';
import CreatePermitPopup from './CreatePermitPopUp/CreatePermitPopup';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { FaPlus, FaEye, FaTimes } from 'react-icons/fa';

function PermitsTable() {
  const { loading, handleCreatePermit, handleViewPermit, rejectPermit } = usePermits();
  const [permits, setPermits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedPermit, setSelectedPermit] = useState(null);
  const [viewPermit, setViewPermit] = useState(null);
  const fetchPermits = async () => {
    try {
      const permitsCollection = collection(db, 'permitRequests');
      const q = query(permitsCollection, where('status', 'not-in', ['Prepared', 'Rejected']));
      const permitsSnapshot = await getDocs(q);
      const permitsList = permitsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPermits(permitsList);
    } catch (error) {
      console.error('Error fetching permits:', error);
    }
  };
  
  useEffect(() => {
    fetchPermits();
  }, [refreshTrigger]);

  if (loading) {
    return <div className="text-white">Loading permits...</div>;
  }

  // Define the specific fields to display
  const fieldsToDisplay = ['fullName', 'dateOfRequest', 'workToBeDone', 'equipment', 'roomNamesNumbers', 'rams', 'isolationRequired', 'sapApRequired'];

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = permits.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const closePopup = () => {
    setSelectedPermit(null);
    setViewPermit(null);
  };

  return (
    <div className="mb-8 bg-gray-900 text-gray-100 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-4 text-blue-400">Permit Requests</h3>
      <div className="overflow-x-auto mb-4">
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="bg-gray-800">
              <th className="border border-gray-700 p-2 whitespace-nowrap">Actions</th>
              {fieldsToDisplay.map((field) => (
                <th key={field} className="border border-gray-700 p-2 whitespace-nowrap">{field}</th>
              ))}
              <th className="border border-gray-700 p-2 whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((permit) => (
              <tr key={permit.id} className="hover:bg-gray-800 transition-colors duration-200">
                <td className="border border-gray-700 p-2 whitespace-nowrap">
                  <button 
                    className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded transition-colors duration-200 mr-2"
                    onClick={() => setSelectedPermit(permit)}
                    title="Create Permit"
                  >
                    <FaPlus />
                  </button>
                  <button 
                    className="bg-green-600 hover:bg-green-700 text-white p-2 rounded transition-colors duration-200 mr-2"
                    onClick={() => handleViewPermit(permit)}
                    title="View Permit"
                  >
                    <FaEye />
                  </button>
                  <button 
                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded transition-colors duration-200"
                    onClick={() => rejectPermit(permit.id)}
                    title="Reject Permit"
                  >
                    <FaTimes />
                  </button>
                </td>
                {fieldsToDisplay.map((field) => (
                  <td key={field} className="border border-gray-700 p-2 whitespace-nowrap">
                    {field === 'dateOfRequest' && permit[field] instanceof Date
                      ? permit[field].toLocaleString()
                      : String(permit[field] || '')}
                  </td>
                ))}
                <td className="border border-gray-700 p-2 whitespace-nowrap">
                  <span className={`px-2 py-1 rounded ${
                    permit.status === 'Approved' ? 'bg-green-600' : 
                    permit.status === 'Pending' ? 'bg-yellow-600' : 'bg-red-600'
                  }`}>
                    {permit.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <span className="mr-2">Items per page:</span>
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className="bg-gray-800 text-white p-1 rounded"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div>
          {Array.from({ length: Math.ceil(permits.length / itemsPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i + 1 ? 'bg-blue-600' : 'bg-gray-700'
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      {selectedPermit && (
        <CreatePermitPopup
          selectedRequest={selectedPermit}
          closePopup={closePopup}
          refreshPermits={() => setRefreshTrigger(prev => prev + 1)}
        />
      )}
    
    </div>
  );
}

export default PermitsTable;
