import React, { useState, useEffect } from 'react';
import { db } from '../../../services/firebase';
import { usePermitContext } from '../PermitsStatus/context/PermitContext';
import { collection, addDoc, deleteDoc, doc, getDocs, query, orderBy, limit, getDoc } from 'firebase/firestore';
import { FaFileAlt, FaBolt, FaCog } from 'react-icons/fa';
import RequestInformation from './RequestInformation';
import LOAForm from './LOAForm';
import EPTWForm from './EPTWForm';
import MPTWForm from './MPTWForm';

function CreatePermitPopup({ closePopup, selectedRequest }) {
  const [activeTab, setActiveTab] = useState('LOA');
  const [permitData, setPermitData] = useState({
    permitType: 'LOA',
    location: Array.isArray(selectedRequest?.location) ? selectedRequest.location : [selectedRequest?.location].filter(Boolean),
    accessToElectrical: '',
    workToBeDone: selectedRequest?.workToBeDone || '',
    datePrepared: new Date().toISOString().split('T')[0],
    rams: selectedRequest?.rams || '',
    // EPTW specific fields
    equipmentDescription: '',
    electricalHazards: '',
    precautionsMeasures: '',
    ppe: '',
    // MPTW specific fields
    mechanicalSystem: '',
    mechanicalHazards: '',
    lockoutProcedures: '',
    specialTools: '',
  });
  const [nextPermitNumber, setNextPermitNumber] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);

  useEffect(() => {
    const fetchNextPermitNumber = async () => {
      const permitsCollection = collection(db, 'permits');
      const q = query(permitsCollection, orderBy('number', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setNextPermitNumber('001');
      } else {
        const highestNumber = querySnapshot.docs[0].data().number;
        const nextNumber = String(Number(highestNumber) + 1).padStart(3, '0');
        setNextPermitNumber(nextNumber);
      }
    };

    fetchNextPermitNumber();
  }, []);

  const handleInputChange = (e) => {
    setPermitData({ ...permitData, [e.target.name]: e.target.value });
  };

  const { refreshPermits } = usePermitContext();
  
  const handleCreatePermit = async () => {
    if (!nextPermitNumber) {
      console.error('Next permit number not available');
      return;
    }

    try {
      const newPermitData = {
        ...permitData,
        number: nextPermitNumber,
        status: 'Prepared',
        createdAt: new Date(),
        type: permitData.permitType,
        // Add fields from selectedRequest
        fullName: selectedRequest?.fullName || '',
        badgeNumber: selectedRequest?.badgeNumber || '',
        company: selectedRequest?.company || '',
        email: selectedRequest?.email || '',
        telephone: selectedRequest?.telephone || '',
        dateOfRequest: selectedRequest?.dateOfRequest || '',
        dateRequired: selectedRequest?.dateRequired || '',
        roomNamesNumbers: selectedRequest?.roomNamesNumbers || '',
        workType: selectedRequest?.workType || '',
        workDuration: selectedRequest?.workDuration || '',
        equipment: selectedRequest?.equipment || '',
        isolationRequired: selectedRequest?.isolationRequired || '',
        sapApRequired: selectedRequest?.sapApRequired || '',
        documentHolder: selectedRequest?.documentHolder || '',
        emailRequestor: selectedRequest?.emailRequestor || '',
        otherInfo: selectedRequest?.otherInfo || '',
      };
      
      const permitsCollection = collection(db, 'permits');
      const docRef = await addDoc(permitsCollection, newPermitData);

      // Delete the original request if it exists
      if (selectedRequest?.id) {
        const permitRequestsCollection = collection(db, 'permitRequests');
        try {
          await deleteDoc(doc(permitRequestsCollection, selectedRequest.id));
          console.log('Successfully deleted request:', selectedRequest.id);
        } catch (error) {
          console.error('Error deleting request:', error);
        }
      }

      // Fetch the newly created permit
      const newPermitDoc = await getDoc(docRef);
      const newPermit = { id: newPermitDoc.id, ...newPermitDoc.data() };

      setPermitData(newPermit);
      setShowTemplate(true);
      refreshPermits(); // Trigger refresh after successful creation
    } catch (error) {
      console.error('Error creating permit:', error);
    }
  };

  const handleCloseTemplate = () => {
    setShowTemplate(false);
    refreshPermits();
    closePopup();
  };


  const renderPermitForm = () => {
    const register = (name, value) => ({
      name,
      value: permitData[name],
      onChange: handleInputChange
    });

    switch (permitData.permitType) {
      case 'LOA':
        return <LOAForm register={register} />;
      case 'EPTW':
        return <EPTWForm register={register} />;
      case 'MPTW':
        return <MPTWForm register={register} />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {!showTemplate ? (
          <>
            <h2 className="text-2xl font-bold mb-4 text-white">Create Permit</h2>
            
            {/* Section 1: Request Information */}
            <RequestInformation selectedRequest={selectedRequest} />

            {/* Section 2: Create Permit */}
            <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
              <h3 className="text-2xl font-bold mb-4 text-white">Create Permit</h3>
              <div className="mb-6">
                <label className="block text-gray-300 mb-2 text-lg">Select Permit Type:</label>
                <div className="flex space-x-4">
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'LOA' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'LOA' ? 'bg-blue-600' : 'bg-gray-700'
                    } hover:bg-blue-500 text-white font-semibold`}
                  >
                    <FaFileAlt className="mr-2" />
                    LOA
                  </button>
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'EPTW' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'EPTW' ? 'bg-red-600' : 'bg-gray-700'
                    } hover:bg-red-500 text-white font-semibold`}
                  >
                    <FaBolt className="mr-2" />
                    EPTW
                  </button>
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'MPTW' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'MPTW' ? 'bg-green-600' : 'bg-gray-700'
                    } hover:bg-green-500 text-white font-semibold`}
                  >
                    <FaCog className="mr-2" />
                    MPTW
                  </button>
                </div>
              </div>
              <div className="bg-gray-700 p-6 rounded-lg">
                {renderPermitForm()}
              </div>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                onClick={handleCreatePermit}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Create Permit
              </button>
              <button
                onClick={closePopup}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleCloseTemplate}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CreatePermitPopup;
