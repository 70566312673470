import { useState } from 'react';
import { PermitService } from '../services/PermitService';

const usePermits = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPermits = async (type, status) => {
    setLoading(true);
    setError(null);
    try {
      const permits = await PermitService.fetchPermits(type, status);
      setLoading(false);
      return permits;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return [];
    }
  };

  const handleViewPermit = async (permitId) => {
    setLoading(true);
    setError(null);
    try {
      const permit = await PermitService.viewPermit(permitId);
      setLoading(false);
      return permit;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  const handleIssuePermit = async (permitId) => {
    setLoading(true);
    setError(null);
    try {
      const result = await PermitService.issuePermit(permitId);
      setLoading(false);
      return result;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  const handleClosePermit = async (permitId) => {
    setLoading(true);
    setError(null);
    try {
      const result = await PermitService.closePermit(permitId);
      setLoading(false);
      return result;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  return {
    loading,
    error,
    fetchPermits,
    handleViewPermit,
    handleIssuePermit,
    handleClosePermit
  };
};

export default usePermits;
