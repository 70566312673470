import React from 'react';
import { NavLink } from 'react-router-dom';
import logoSAST from '../assets/logo.png';
import { FaChartBar, FaEye, FaExclamationTriangle, FaFileAlt, FaBolt, FaProjectDiagram, FaUserPlus, FaClipboardList } from 'react-icons/fa';

const menuItems = [
  { name: 'Dashboard', path: '/dashboard', icon: FaChartBar },
  { name: 'Audits', path: '/Issues', icon: FaEye },
  { name: 'Tracker', path: '/Etracker', icon: FaExclamationTriangle },
  { name: 'Permits', path: '/permits', icon: FaFileAlt },
  { name: 'Permit Requests', path: '/permit-requests', icon: FaFileAlt },
  { name: 'Asset Register', path: '/assetregister', icon: FaBolt },
  { name: 'Mimic', path: '/Mimic', icon: FaProjectDiagram },
  { name: 'CP Request', path: '/cp-request', icon: FaUserPlus },
  { name: 'CP Register', path: '/cp-register', icon: FaClipboardList },
];

function Sidebar({ isOpen, toggleSidebar }) {
  return (
    <div className={`bg-gray-800 text-white h-screen ${isOpen ? 'w-64' : 'w-16'} flex flex-col transition-all duration-300 ease-in-out`}>
      <div className="p-4">
        {isOpen ? (
          <img src={logoSAST} alt="Logo" className="w-full mb-4" />
        ) : (
          <div className="w-8 h-8 bg-white rounded-full mb-4 mx-auto"></div>
        )}
      </div>
      <nav className="flex-grow">
        <ul>
          {menuItems.map((item) => (
            <li key={item.name} className="mb-2">
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `block px-4 py-2 hover:bg-gray-700 ${isActive ? 'bg-gray-700' : ''} ${isOpen ? '' : 'text-center'}`
                }
                title={item.name}
              >
                {isOpen ? (
                  <span>{item.name}</span>
                ) : (
                  <item.icon className="mx-auto" size={20} />
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <button
        onClick={toggleSidebar}
        className="bg-gray-700 text-white p-2 m-2 rounded-md focus:outline-none self-end"
      >
        {isOpen ? '<' : '>'}
      </button>
    </div>
  );
}

export default Sidebar;
