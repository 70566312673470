import React, { useState } from 'react';
import { Search, Upload } from 'lucide-react';

const IssuesTabs = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { id: 'ALL', label: 'ALL', count: 0 },
    { id: 'OPEN', label: 'OPEN', count: 0 },
    { id: 'CLOSED', label: 'CLOSED', count: 0 },
  ];

  return (
    <div className="flex border-b">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`px-4 py-2 ${
            activeTab === tab.id
              ? 'border-b-2 border-cyan-500 text-cyan-500'
              : 'text-gray-500'
          }`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label} ({tab.count})
        </button>
      ))}
    </div>
  );
};

const AddIssueForm = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">Report an Observation</h2>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Occurred on</label>
          <input
            type="datetime-local"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-500 focus:ring-opacity-50"
            defaultValue="2024-08-08T12:26"
          />
        </div>
        <div className="mb-4">
          <button type="button" className="flex items-center text-cyan-500">
            <Upload size={16} className="mr-2" />
            Upload Image
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Describe what you observed</label>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-500 focus:ring-opacity-50"
            rows="3"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Select observation type</label>
          <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-500 focus:ring-opacity-50">
            <option>uiuiui9999</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Select Location</label>
          <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-500 focus:ring-opacity-50">
            <option>Cyrus One</option>
          </select>
        </div>
        <div className="mb-4">
          <button type="button" className="text-cyan-500">+ Add Action</button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Severity / Impact Level</label>
          <div className="mt-1 flex">
            <button type="button" className="px-3 py-1 border border-gray-300 rounded-l-md">Minor</button>
            <button type="button" className="px-3 py-1 border-t border-b border-gray-300">Serious</button>
            <button type="button" className="px-3 py-1 border border-gray-300 rounded-r-md">Fatal</button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Comment</label>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-500 focus:ring-opacity-50"
            rows="3"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-cyan-500 text-white py-2 px-4 rounded-md hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default function Issues() {
  const [activeTab, setActiveTab] = useState('ALL');

  return (
    <div className="flex">
      <div className="w-2/3 pr-4">
        <div className="bg-white rounded-lg shadow p-4">
          <IssuesTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="mt-4 flex justify-between items-center">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
              />
              <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
            </div>
            <button className="bg-white text-cyan-500 border border-cyan-500 px-4 py-2 rounded-md hover:bg-cyan-50">
              Export
            </button>
          </div>
          {/* Add your issues list here */}
          <div className="mt-4">
            {/* This is where you would map through your issues and display them */}
            <p className="text-gray-500">No issues to display.</p>
          </div>
        </div>
      </div>
      <div className="w-1/3">
        <AddIssueForm />
      </div>
    </div>
  );
}